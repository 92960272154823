import React, { useState, useEffect } from "react";
import { PlaidLink } from "react-plaid-link";
import Loader from "../../common/Loader";
import { getPlaidTokenDetailsHandler } from "../../../actions/user/action";
import { purchaseProducts } from "../../../actions/product/action";
import { connect } from "react-redux";
import { navigate } from "gatsby-link";
import Toaster from "../../common/Toaster";
const _ = require("lodash");

const PlaidComponent = ({
  getPlaidTokenDetailsHandler,
  state,
  purchaseProducts,
}) => {
  const [loading, setLoading] = useState(false);
  const [plaidToken, setPlaidToken] = useState("");
  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");
  const [type, setType] = useState("error");

  useEffect(async () => {
    setLoading(true);

    if (!state.userDetails || !state.userDetails.shippingAddress) {
      navigate("/products/cart/checkout-details");
      return;
    }

    const chosenShippingAddress = state.userDetails.shippingAddress.find(
      (e) => e.display === true
    );
    const chosenBillingAddress = state.userDetails.billingAddress.find(
      (e) => e.display === true
    );

    const userDetails = {
      name: state.userDetails.user.name,
      email: state.userDetails.user.email,
      phoneNumber: state.userDetails.user.phone,
      userId: chosenShippingAddress.userId,
      brandId: state.userDetails.user.brand,
    };
    const shippingAddressDetails = {
      address: chosenShippingAddress.address,
      city: chosenShippingAddress.city,
      shippoId: chosenShippingAddress.shippoId,
      state: chosenShippingAddress.state,
      suite: chosenShippingAddress.suite,
      zip: chosenShippingAddress.zip,
      referenceId: chosenShippingAddress._id,
    };
    const billingAddressDetails = {
      address: chosenBillingAddress.address,
      city: chosenBillingAddress.city,
      shippoId: chosenBillingAddress.shippoId,
      state: chosenBillingAddress.state,
      suite: chosenBillingAddress.suite,
      zip: chosenBillingAddress.zip,
      referenceId: chosenBillingAddress._id,
    };

    const obj = {
      token: { id: "" },
      einNumber: state.userDetails.ein ? state.userDetails.ein.toString() : "",
      userDetails,
      shippingAddressDetails,
      billingAddressDetails,
      payment_source: "ACH",
      source: null,
    };
    // console.log(obj);

    const getPlaidToken = await getPlaidTokenDetailsHandler(obj);

    if (_.get(getPlaidToken, "responseData.achDetails.ACHLink.link_token")) {
      setPlaidToken(getPlaidToken.responseData.achDetails.ACHLink.link_token);
    } else {
      navigate("/products/cart/checkout-details");
    }

    setLoading(false);
  }, []);

  const successPlaid = (publicToken, metadata) => {
    createACHSourceHandlerFunction({ token: publicToken, metadata });
  };

  const createACHSourceHandlerFunction = async (token) => {
    if (
      state.userDetails.user.email &&
      state.userDetails.shippingAddress.length > 0 &&
      state.userDetails.billingAddress.length > 0
    ) {
      if (
        token &&
        token.metadata &&
        token.metadata.transfer_status === "COMPLETE"
      ) {
        const chosenShippingAddress = state.userDetails.shippingAddress.find(
          (e) => e.display === true
        );
        const chosenBillingAddress = state.userDetails.billingAddress.find(
          (e) => e.display === true
        );

        const userDetails = {
          name: state.userDetails.user.name,
          email: state.userDetails.user.email,
          phoneNumber: state.userDetails.user.phone,
          userId: chosenShippingAddress.userId,
          brandId: state.userDetails.user.brand,
        };
        const shippingAddressDetails = {
          address: chosenShippingAddress.address,
          city: chosenShippingAddress.city,
          shippoId: chosenShippingAddress.shippoId,
          state: chosenShippingAddress.state,
          suite: chosenShippingAddress.suite,
          zip: chosenShippingAddress.zip,
          referenceId: chosenShippingAddress._id,
        };
        const billingAddressDetails = {
          address: chosenBillingAddress.address,
          city: chosenBillingAddress.city,
          shippoId: chosenBillingAddress.shippoId,
          state: chosenBillingAddress.state,
          suite: chosenBillingAddress.suite,
          zip: chosenBillingAddress.zip,
          referenceId: chosenBillingAddress._id,
        };

        const obj = {
          // ...token,,
          token: { id: null },
          einNumber: state.userDetails.ein ? state.userDetails.ein : "",
          userDetails,
          shippingAddressDetails,
          billingAddressDetails,
          payment_source: "ach",
          source: JSON.stringify(token),
          selfPickup:(state.userDetails && state.userDetails.selfPickup) ? true : false
        };

        setToastrMsg("Payment Successful!");
        setType("success");
        doRefresh((prev) => prev + 1);

        // console.log(obj);

        const purchase = await purchaseProducts(obj);
        // navigate("/checkout/thank-you");
      }
    }
  };

  return (
    <>
      <Loader isLoading={loading} />
      <Toaster refresh={refresh} type={type} message={toastrMsg} />
      <PlaidLink
        token={plaidToken}
        env="sandbox"
        onSuccess={successPlaid}
        onExit={() => {
          console.log("exiting");
        }}
        className="plaid-success"
      >
        Connect Bank Account
      </PlaidLink>
    </>
  );
};

const mapStateToProps = (state) => ({
  state: state.data.userDetails,
});

export default connect(mapStateToProps, {
  getPlaidTokenDetailsHandler,
  purchaseProducts,
})(PlaidComponent);
